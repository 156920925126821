import i18n from '../../i18n'
import { endpointCall } from '../../api/endpointCall'
//import { clearStorage } from 'src/utils/browserStorage'
//import { routesCorresponding } from 'src/utils/routes/routesCorresponding'
import { mercureToken } from 'src/utils/config/browserStorageNames'
import { jwtDecode } from 'jwt-decode'
import { TokenMercureInterface } from 'src/typesPerso/TokenMercureInterface'
import { getMercureJWT } from 'src/api/sf/endpoints'
import { Toast } from 'primereact/toast'
//import { appSupport } from 'src/utils/config/appSupport'
//import { clearAllMercureSubscriptions } from './clearAllMercureSubscriptions'

/**
 * Cette fonction gère la reconnexion à mercure.
 * Elle vide le tableau des eventSource et renouvelle le JWT
 * @param {function} setIsTokenRefreshed fonction pour mettre à jour la variable isRefreshToken pour relancer la connexion
 * @param {Object} toast objet de type toast (primeReactComponent) pour afficher une erreur si trop de tentatives de reconnexion
 */
export const MercureReconnectionManager = async (
  setIsTokenRefreshed: (isTokenRefreshed: boolean) => void,
  toast: Toast,
) => {
  //on regare si le JWS mercure est expiré
  let needToRefreshJWS = false
  const mercureJWT = sessionStorage.getItem(mercureToken)
  console.log('mercureJWS avant renouvelement : ', mercureJWT)
  if (mercureJWT) {
    //si le JWS est présent dans le sessionStorage, on vérifie sa validité
    const exp = jwtDecode<TokenMercureInterface>(mercureJWT).exp
    const now = Math.ceil(Date.now() / 1000)
    needToRefreshJWS = exp === null ? true : exp < now //si exp null (=pas de jwt) on tente le refresh sinon on regarde le delais d'expiration
    console.log("le JWS a besoin d'être rafraichi : ", needToRefreshJWS)
  } else {
    console.log('pas de JWS dans le session storage, on déconnecte')
    //si le JWS n'est pas présent dans le sessionStorage, on déconnecte
    return false
  }

  if (needToRefreshJWS) {
    //le JWS est expiré
    const a = await getNewJWS(setIsTokenRefreshed)
    if (!a) {
      return false
    }
  } else {
    actionError(toast)
  }
  return true
}

/**
 * Fonction appelée si on a échoué lors de trop de reconnexion mercure
 * //afficher un message à l'utilisateur
 */
export function actionError(toast: any) {
  toast.current.show({
    severity: 'warn',
    content: (
      <div style={{ width: '100%' }}>
        <div>
          {i18n.t('errorsMessages.tooManyFailedAttemptsMessage1', {
            ns: 'errorsMessages',
          })}
        </div>
        <div>
          {i18n.t('errorsMessages.tooManyFailedAttemptsMessage2', {
            ns: 'errorsMessages',
          })}
        </div>
        {/* <div>
          <a href={`mailto:${appSupport}`}>{appSupport}</a>
        </div> */}
      </div>
    ),
    sticky: true,
  })
}

//fonction qui renouvelle le JWS
const getNewJWS = async (setIsRefreshedToken: Function) => {
  const result = await endpointCall(getMercureJWT())
  console.log('renouvellement du JWS : ', result)
  if (result.success) {
    console.log('renouvellement du JWS succès : ', result.success)
    sessionStorage.setItem(mercureToken, result.response.data.token)
    setIsRefreshedToken(true)
  } else {
    console.log('renouvellement du JWS échec : ', result.success)
    return false
  }
  return true
}
