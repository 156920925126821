import { eventSourceCollections } from 'src/typesPerso/eventSourceCollections'
import { subscribeToMercureTopic } from './subscribeToMercureTopic'
export let savedEventSourceCollection: eventSourceCollections = {}
export let savedEventSourceCollectionPermanent: eventSourceCollections = {}

/**
 * récupère la liste des topics écoutés
 * compare avec la liste des topics à écouter (en paramètres)
 * ferme les topics plus utiles et ajoute les éventuels nouveaux topics
 * @param {*} topics : liste des topics
 * @param {boolean} isPermanentTopics : indique si ce sont des topics permanents ou non
 */
export function manageMercureSubscriptions(
  topics: Array<string>,
  isPermanentTopics: boolean,
) {
  const topicsCollection = isPermanentTopics
    ? savedEventSourceCollectionPermanent
    : savedEventSourceCollection

  const listenedTopics = Object.keys(topicsCollection)

  const topicsToAdd = topics.filter(
    (topic) => listenedTopics.indexOf(topic) === -1,
  )

  //Ajout des topicsToAdd
  topicsToAdd.forEach((t) => {
    const eS = subscribeToMercureTopic(t)
    eS.onerror = () => {
      console.log('manageMercureSubscriptions, eventSource on error : ', eS)
    }
    topicsCollection[t] = eS
  })

  if (!isPermanentTopics) {
    const topicsToRemove = listenedTopics.filter(
      (topic: string) => topics.indexOf(topic) === -1,
    )

    //suppression des topicsToRemove
    topicsToRemove.forEach((t: string) => {
      topicsCollection[t].close()
      delete topicsCollection[t]
    })
  }
}
