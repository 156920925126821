import { useContext } from 'react'
import { Navigate } from 'react-router-dom'
import { AuthContext } from 'src/store/authContext'
import Home from 'src/views/home/Home'

/**
 * Composant appelé si l'utilisateur saisie la route "/"
 * @returns page de login si utilisateur non authentifié et page home sinon
 */

const RootPage = () => {
  const { token } = useContext(AuthContext) //Récupère le token

  return <>{token ? <Home /> : <Navigate to={'/login'} replace />}</>
}

export default RootPage
