import { Avatar } from '@mui/material'
import { deepPurple } from '@mui/material/colors'
import { useContext } from 'react'
import { AuthContext } from 'src/store/authContext'

interface AvatarInitialProps {
  className?: string
  style?: any
}

const AvatarInitial: React.FC<AvatarInitialProps> = (props) => {
  //On récupère les données utilisateur dans le store contexte ainsi que la fonction de déconnexion
  const { firstname, lastname } = useContext(AuthContext)

  function stringAvatar(name: string) {
    return {
      sx: {
        bgcolor: deepPurple[900],
        fontSize: '1rem',
        width: '2rem',
        height: '2rem',
        ...props.style,
      },
      children: `${name?.split(' ')[0][0]?.toUpperCase()}${name
        ?.split(' ')[1][0]
        ?.toUpperCase()}`,
    }
  }

  return (
    <Avatar
      className={props.className}
      {...stringAvatar(`${firstname} ${lastname}`)}
    />
  )
}

export default AvatarInitial
