import { createContext, useState } from 'react'
import { getAllowedRoutes } from 'src/utils/routes/getAllowedRoutes'
import { routes as AppRoutes } from 'src/utils/routes/routes'

export const RouterContext = createContext({
  routes: getAllowedRoutes(AppRoutes),
  handleUpdateRoutes: (router: any) => {},
})

export const RouterContextProvider = (props: any) => {
  const [routes, setRoutes] = useState(getAllowedRoutes(AppRoutes))

  const handleUpdateRoutes = (routes: any) => {
    setRoutes(routes)
  }

  const contextValue = {
    routes: routes,
    handleUpdateRoutes: handleUpdateRoutes,
  }

  return (
    <RouterContext.Provider value={contextValue}>
      {props.children}
    </RouterContext.Provider>
  )
}
