import React, { useContext } from 'react'
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CHeaderNav,
  CRow,
} from '@coreui/react'
import AppHeaderDropdownLanguage from 'src/layout/protected/header/AppHeaderDropdownLanguage'
import style from './PublicLayout.module.css'
import logo from 'src/assets/logohc.png'
import { Outlet } from 'react-router-dom'
import { AnimationContext } from 'src/store/animationContext'
import { getUserId } from 'src/utils/helpers'
import Home from 'src/views/home/Home'

interface PublicLayoutProps {
  classNameAnimation?: string
}

const PubilcLayout: React.FC<PublicLayoutProps> = ({ classNameAnimation }) => {
  const { animationClassname } = useContext(AnimationContext)

  let userId = null

  if (window.location.pathname.includes('/email/verify/')) {
    userId = null
  } else {
    userId = getUserId()
  }

  return (
    <>
      {userId ? (
        <Home />
      ) : (
        <div className={style.main}>
          <div className={style.context}>
            <CHeaderNav className="pt-3 me-3 justify-content-end">
              <AppHeaderDropdownLanguage />
            </CHeaderNav>
            <div className="min-vh-max d-flex flex-row align-items-center ">
              <CContainer>
                <CRow className="justify-content-center">
                  <CCol sm={8} md={6} lg={4} xl={4} xs={12}>
                    <CCardGroup className={animationClassname}>
                      <CCard>
                        <CCardBody className="p-4 text-center">
                          <img
                            className={style.logo}
                            src={logo}
                            alt="HC conseil"
                            width={143}
                            height={200}
                          />
                          <Outlet />
                        </CCardBody>
                      </CCard>
                    </CCardGroup>
                  </CCol>
                </CRow>
              </CContainer>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default PubilcLayout
