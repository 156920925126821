import axios from 'axios'
import { refreshJwt } from './refreshJwt'
import { getJwt } from '../utils/helpers'

/**
 * Instance axios
 */
const baseAxios = axios.create({})
/**
 * Create instance axios and intercept request to check if token is valid
 * then add token to request header
 */
baseAxios.interceptors.request.use(async (config: any) => {
  //si l'URL n'est pas publique, on vérifie le JWT
  if (!config.public) {
    await refreshJwt()
  }

  const token = getJwt()

  if (config.baseURL === process.env.REACT_APP_SF_USER_API) {
    //nécessite d'avoir un en-tête CORS Access-Control-Allow-Credentials à true côté back
    config.withCredentials = true
  }

  if (!config.public && token) {
    config.headers!.Authorization = 'Bearer ' + token
  }
  config.headers!['Accept'] = 'application/json'
  config.headers!['Content-type'] = 'application/json'
  if (config.method === 'patch') {
    config.headers!['Content-type'] = 'application/merge-patch+json'
  }
  //Si mes données sont un formdata (upload de fichier)
  if (config.data instanceof FormData) {
    config.headers!['Content-type'] = 'multiform-data'
  }

  if (config.url.includes('pdf')) {
    config.headers!['responseType'] = 'blob'
  }

  return config
})

export default baseAxios
