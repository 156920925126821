import { ListItemButton, useMediaQuery } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import colorConfigs from '../configs/colorConfigs'
import style from './SidebarItem.module.css'
import { useTranslation } from 'react-i18next'
import { RootState } from 'src/store/store'
import { RouteInterface } from 'src/typesPerso/Route'
import { reloadComponentActions } from 'src/store/features/reloadComponent/reloadComponentSlice'
import { sidebarActions } from 'src/store/features/sidebar/sidebarSlice'

type Props = {
  item: RouteInterface
}

const SidebarItem = ({ item }: Props) => {
  const { appState } = useSelector((state: RootState) => state.appState)
  const { t } = useTranslation(['routes'])
  const level = item.sidebarProps!.style
  const dispatch = useDispatch()
  const isReloadComponentToggled = useSelector(
    (state: RootState) => state.reloadComponent.isReloadComponentToggled,
  ) //Action de rechargement du composant lorsqu'on clique sur son lien de navigation et qu'il est déjà monté
  //Utilisé pour le responsive sur petit écran
  const isMediumScreen = useMediaQuery('(max-width:767px)')

  return item.sidebarProps && item.path ? (
    <div className={style[level]}>
      <ListItemButton
        component={Link}
        to={item.path}
        sx={{
          '&: hover': {
            backgroundColor: colorConfigs.sidebar.hoverBg,
            color: 'white',
          },
          backgroundColor:
            appState === item.state ? colorConfigs.sidebar.activeBg : 'unset',
          color: appState === item.state ? 'white' : 'hsla(0,0%,100%,.6)',
          fontWeight: appState === item.state ? 'bold' : 'unset',
          //paddingY: "12px",
          paddingX: '0px',
          // color: "hsla(0,0%,100%,.6)",
        }}
        onClick={() => {
          //Action de rechargement au clique si le l'élément est un lien de navigation (et non un titre ou un nav group)
          dispatch(reloadComponentActions.toggle(!isReloadComponentToggled))
          //Réduction de la sidebar sur petits écrans
          if (isMediumScreen) {
            dispatch(sidebarActions.show(false))
          }
        }}
      >
        {/* <ListItemIcon sx={{ color: colorConfigs.sidebar.color }}> */}
        <span
          className="d-flex justify-content-center align-items-center ms-2 me-3"
          style={{
            width: '25px',
            height: '25px',
          }}
        >
          {item.sidebarProps.icon}
        </span>
        {/* </ListItemIcon> */}
        {t(`routes.${item.sidebarProps.displayText}`)}
      </ListItemButton>
    </div>
  ) : null
}

export default SidebarItem
