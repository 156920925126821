import { createSlice } from '@reduxjs/toolkit'
import { StateInterface } from 'src/typesPerso/State'

const initialState: StateInterface['mercureAutoReconnection'] = {
  nbMercureAutoReconnectionAttempt: 0,
}

export const mercureAutoReconnectionSlice = createSlice({
  name: 'mercureAutoReconnection',
  initialState,
  reducers: {
    resetNbMercureAutoReconnectionAttempt: (state) => {
      state.nbMercureAutoReconnectionAttempt = 0
      return state
    },
    incrementNbMercureAutoReconnectionAttempt: (state) => {
      state.nbMercureAutoReconnectionAttempt += 1
      return state
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  resetNbMercureAutoReconnectionAttempt,
  incrementNbMercureAutoReconnectionAttempt,
} = mercureAutoReconnectionSlice.actions

export default mercureAutoReconnectionSlice.reducer
