import {
  savedEventSourceCollection,
  savedEventSourceCollectionPermanent,
} from './manageMercureSubscriptions'
import {
  resetLastEventIDStorage,
  resetOnMessageFunctions,
} from './useMercureSubscriptionsManager'

/**
 * ferme tous les topics
 * vide l'objet des lastEventId
 * vide l'objet des fonctions onMessages
 */
export function clearAllMercureSubscriptions() {
  const listenedTopics = Object.keys(savedEventSourceCollection)
  const listenedTopicsPermanents = Object.keys(
    savedEventSourceCollectionPermanent,
  )

  listenedTopics.forEach((t: string) => {
    savedEventSourceCollection[t].close()
    delete savedEventSourceCollection[t]
  })
  listenedTopicsPermanents.forEach((t: string) => {
    savedEventSourceCollectionPermanent[t].close()
    delete savedEventSourceCollectionPermanent[t]
  })
  resetLastEventIDStorage() //vide le tableau des lastEventId
  resetOnMessageFunctions() //vide le tableau des onMessageFunctions
}
