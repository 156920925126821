import { getExp } from 'src/utils/helpers'
import { clearStorage, setToken } from 'src/utils/browserStorage'
import { endpointCall } from './endpointCall'
import { refreshToken } from './sf/endpoints'
import { clearAllMercureSubscriptions } from 'src/components/dataManager/clearAllMercureSubscriptions'

// function to get exp date token and fetch refresh if exp date is coming, set new token or disconnect
export const refreshJwt = async () => {
  const exp = getExp()
  const now = Math.ceil(Date.now() / 1000)
  const delay = 2
  const needToRefresh = exp === null ? true : exp < now + delay //si exp null (=pas de jwt) on tente le refresh sinon on regarde le delais d'expiration
  // if (exp !== null) {
  //   const needToRefresh = exp < now + delay
  if (needToRefresh) {
    const result = await endpointCall(refreshToken())
    if (result.success === true) {
      setToken(result.response.data.token)
    } else {
      clearAllMercureSubscriptions()
      clearStorage()
      //sessionStorage.setItem('offline', 'true')
      window.location.replace(window.location.origin + '/offline')
    }
  }
  //}
}
