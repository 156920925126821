import { EventSourcePolyfill } from 'event-source-polyfill'
import { mercureToken } from '../../utils/config/browserStorageNames'
import { lastEventIDStorage } from './useMercureSubscriptionsManager'

/**
 * Function to return eventSource for mercure
 * @param {topic} topic mercure topic
 * @returns EventSource
 */
export const subscribeToMercureTopic = (topic: string) => {
  const url = new URL(process.env.REACT_APP_MERCURE_URL!)
  url.searchParams.append('topic', topic)
  if (lastEventIDStorage[topic]) {
    url.searchParams.append('lastEventId', lastEventIDStorage[topic])
  }
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    // dev code
    const token = sessionStorage.getItem(mercureToken)
    return new EventSourcePolyfill(url.toString(), {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
  } else {
    // production code
    return new EventSource(url, { withCredentials: true })
  }
}
