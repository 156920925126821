import { Navigate } from 'react-router-dom'

const Home = () => {
  // Rediriger l'utilisateur
  return (
    <>
      <Navigate to={'/contacts'} />
    </>
  )
}

export default Home
