import { useRef } from 'react'
import { OverlayPanel } from 'primereact/overlaypanel'
import { Tooltip } from 'primereact/tooltip'

function TruncatedText(props: {
  text: string | null | undefined
  maxLength: number
  className?: string
  tooltip?: boolean
  style?: any
}) {
  const popover = useRef<any>(null)
  if (props.text === null || props.text === undefined) {
    return null
  }
  const truncatedText =
    props.text?.length > props.maxLength
      ? props.text.slice(0, props.maxLength) + '...'
      : props.text

  const handleClick = (e: any) => {
    popover?.current?.toggle(e)
  }

  return (
    <>
      {props.text?.length > props.maxLength ? (
        props.tooltip ? (
          <Tooltip style={{ zIndex: '4000' }} target="#tooltip" />
        ) : (
          <OverlayPanel
            style={{ width: '40%', wordBreak: 'break-word' }}
            ref={popover}
            pt={{
              root: {
                style: {
                  maxHeight: '90vh',
                  overflow: 'auto',
                  transform: 'translateY(5%)',
                },
              },
            }}
          >
            {props.text}
          </OverlayPanel>
        )
      ) : (
        <></>
      )}

      <span
        id="tooltip"
        {...(props.tooltip && props.text?.length > props.maxLength
          ? { 'data-pr-tooltip': props.text, 'data-pr-position': 'bottom' }
          : {})}
        onClick={handleClick}
        style={{
          cursor:
            props.text?.length > props.maxLength
              ? props.tooltip
                ? 'default'
                : 'context-menu'
              : 'default',
          ...props.style,
        }}
        className={props.className ? props.className : ''}
      >
        {truncatedText}
      </span>
    </>
  )
}

export default TruncatedText
