import { AppHeader } from './index'
import { Outlet, useNavigate } from 'react-router-dom'
import AppSidebar from './AppSideBar'
import { Backdrop } from '@mui/material'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { backdropShowActions } from 'src/store/features/backdrop/backdropShowSlice'
import { StateInterface } from 'src/typesPerso/State'
import { useMercureSubscriptionsManager } from 'src/components/dataManager/useMercureSubscriptionsManager'
import { getUserId } from 'src/utils/helpers'
import { useContext, useRef } from 'react'
import { AuthContext } from 'src/store/authContext'
import { Toast } from 'primereact/toast'

const DefaultLayout = () => {
  const toast = useRef<Toast>(null)
  //On récupère l'état du store pour afficher le backdrop
  const dispatch = useDispatch()
  const isBackdropShown = useSelector(
    (state: StateInterface) => state.backdropShow.isBackdropShown,
  )

  const functionsMercure: any = {}

  // //MERCURE TOPIC PERSON
  // const [topicPerson, setTopicPeron] = useState('')
  // //Fonction exécutée à la réception d'un message sur le topic people/id
  // function onMessagePeople(data: any) {
  //   //Si le prénom de l'utilisateur a été modifié
  //   if (data.firstname !== firstname) {
  //     changeFirstname(data.firstname)
  //   }
  //   //Si le nom de l'utilisateur a été modifié
  //   if (data.lastname !== lastname) {
  //     changeLastname(data.lastname)
  //   }
  // }

  //functionsMercure[topicPerson] = onMessagePeople

  //Ajout du topic utilisateur courant
  const userId = getUserId() //On récupère l'id de l'utilisateur
  const topicUser = process.env.REACT_APP_MERCURE_PREFIX + `/users/${userId}`
  const navigate = useNavigate()
  //const { handleUpdateRoutes } = useContext(RouterContext)
  const { firstname, lastname, changeFirstname, changeLastname, logout } =
    useContext(AuthContext) //Permet de modifier le nom et prénom de l'utilisateur d'après les données mercure

  //Fonction exécutée à la réception d'un message sur le topic users/id
  function onMessageUser(data: any) {
    //Si l'utilisateur est désactivé on le déconnecte
    if (data.active === false) {
      logout()
      navigate('/offline')
    }
    //Si le prénom de l'utilisateur a été modifié
    if (data.person.firstname !== firstname) {
      changeFirstname(data.person.firstname)
    }
    //Si le nom de l'utilisateur a été modifié
    if (data.person.lastname !== lastname) {
      changeLastname(data.person.lastname)
    }
  }

  functionsMercure[topicUser] = onMessageUser

  useMercureSubscriptionsManager(functionsMercure, true, toast)

  return (
    <>
      <Toast ref={toast} />
      <Backdrop
        sx={{
          color: '#fff',
          backdropFilter: 'blur(2px)',
          backgroundColor: 'transparent',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={isBackdropShown}
        onClick={() => dispatch(backdropShowActions.show(!isBackdropShown))}
      ></Backdrop>
      <AppSidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <AppHeader />
        <div className="body flex-grow-1 p-2">
          <Outlet />
        </div>
      </div>
    </>
  )
}

export default DefaultLayout
