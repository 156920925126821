//Fonction de copie profonde d'un objet
//cet objet peut contenir des tableaux d'objets
export function deepCopy(obj: any) {
  if (typeof obj !== 'object' || obj === null) {
    return obj
  }

  let copy: any = Array.isArray(obj) ? [] : {}

  Object.keys(obj).forEach((key) => {
    copy[key] = deepCopy(obj[key])
  })

  return copy
}
