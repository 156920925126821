import i18n from 'src/i18n'

//language par défaut
export const defaultLanguage = 'fr'

//liste des langues gérées par l'application
export const supportedLanguages = ['en', 'fr']

//Récupérer la langue sélectionnée
export const getSelectedLanguage = () => {
  return i18n.language ? i18n.language : sessionStorage.getItem('i18nextLng')
}
