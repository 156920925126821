import React from 'react'
import { useLocation } from 'react-router-dom'
import { CBreadcrumb, CBreadcrumbItem } from '@coreui/react'
import { RouteInterface } from 'src/typesPerso/Route'
import { routes } from 'src/utils/routes/routes'
import { useTranslation } from 'react-i18next'

const AppBreadcrumb = () => {
  const currentLocation = useLocation().pathname
  const { t } = useTranslation('routes')

  const getRouteName = (pathname: string, routes: RouteInterface[]): string => {
    if (pathname === '/profile') {
      return t('routes.profile')
    }
    if (pathname === '/settings') {
      return t('routes.settings')
    }
    if (pathname.startsWith('/contacts')) {
      return t('routes.contacts')
    }

    if (pathname.startsWith('/prospects')) {
      return t('routes.prospects')
    }

    if (pathname.startsWith('/clients')) {
      return t('routes.clients')
    }

    for (const route of routes) {
      if (route.path && route.path === pathname) {
        if (route.sidebarProps && route.sidebarProps.displayText) {
          const breadCrumbTextTmp = route.path.slice(1).split('/')
          let breadCrumbText = ''
          breadCrumbTextTmp.forEach((b, index) => {
            breadCrumbText += t('routes.' + b)
            if (index < breadCrumbTextTmp.length - 1) {
              breadCrumbText += ' / '
            }
          })

          return breadCrumbText
        } else return ''
      }
      if (route.children) {
        const foundPath = getRouteName(pathname, route.children)
        if (foundPath) {
          return foundPath
        }
      }
    }
    // If the targetPath is not found in the current route or its children, return null
    return ''
  }
  const breadcrumb = getRouteName(currentLocation, routes)

  return (
    <CBreadcrumb className="m-0">
      <CBreadcrumbItem>
        <div> {breadcrumb ? breadcrumb : ''}</div>
      </CBreadcrumbItem>
    </CBreadcrumb>
  )
}

export default React.memo(AppBreadcrumb)
