import { useSelector, useDispatch } from 'react-redux'
import {
  CContainer,
  CHeader,
  CHeaderBrand,
  CHeaderNav,
  CHeaderToggler,
  CNavItem,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilMenu } from '@coreui/icons'

import { AppBreadcrumb } from './index'
import { AppHeaderDropdown, AppHeaderDropdownLanguage } from './index'
import { sidebarActions } from 'src/store/features/sidebar/sidebarSlice'
import { StateInterface } from 'src/typesPerso/State'

const AppHeader = () => {
  const dispatch = useDispatch()
  const isSidebarShown = useSelector(
    (state: StateInterface) => state.sidebar.isSidebarShown,
  )

  return (
    <CHeader>
      <CContainer fluid>
        <CHeaderToggler
          className="ps-1"
          onClick={() => {
            dispatch(sidebarActions.show(!isSidebarShown))
          }}
        >
          <CIcon icon={cilMenu} size="lg" />
        </CHeaderToggler>
        <CHeaderBrand className="mx-auto d-md-none"></CHeaderBrand>
        <CHeaderNav className="d-none d-md-flex me-auto ">
          <CNavItem>
            <AppBreadcrumb />
          </CNavItem>
        </CHeaderNav>
        <CHeaderNav className="ms-3">
          <AppHeaderDropdownLanguage />
        </CHeaderNav>
        <CHeaderNav className="ms-3 userMenu">
          <AppHeaderDropdown />
        </CHeaderNav>
      </CContainer>
    </CHeader>
  )
}

export default AppHeader
