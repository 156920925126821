import { useSelector, useDispatch } from 'react-redux'
import { CSidebar, CSidebarBrand } from '@coreui/react'

import logo from 'src/assets/logohc.png'
import style from './AppSidebar.module.css'

import { useTranslation } from 'react-i18next'

// sidebar nav config
import { Link } from 'react-router-dom'
import { sidebarActions } from 'src/store/features/sidebar/sidebarSlice'
import { StateInterface } from 'src/typesPerso/State'
import packageInfo from '../../../package.json'
import SidebarItemCategory from './sidebarMenu/SidebarItemCategory'
import SidebarItemCollapse from './sidebarMenu/SidebarItemCollapse'
import SidebarItem from './sidebarMenu/SidebarItem'
import { RouteInterface } from 'src/typesPerso/Route'
import { routes } from 'src/utils/routes/routes'
import { getAllowedNavigation } from 'src/utils/routes/getAllowedNavigation'

const AppSidebar = () => {
  const { t } = useTranslation([])
  const dispatch = useDispatch()
  const isSidebarShown = useSelector(
    (state: StateInterface) => state.sidebar.isSidebarShown,
  )
  const navigations = getAllowedNavigation(routes)

  //On filtre les éléments de navigations disponible suivant les roles

  return (
    <CSidebar
      position="fixed"
      visible={isSidebarShown}
      onVisibleChange={(visible) => {
        dispatch(sidebarActions.show(visible))
      }}
    >
      <CSidebarBrand className="d-md-flex mt-4">
        <div className={style.logo}>
          <Link to="/">
            <img className={style.img} src={logo} alt="hc conseil" />
          </Link>
        </div>
      </CSidebarBrand>
      <div style={{ overflow: 'auto', marginTop: '20px' }}>
        {/* PARTIE NAVIGATION */}
        {navigations
          ? navigations
              .find((item) => item.id === 'defaultLayout')
              ?.children?.map((route: RouteInterface, index: number) =>
                route.sidebarProps ? (
                  route.sidebarProps.mainMenu ? (
                    <SidebarItemCategory item={route} key={index} />
                  ) : route.children ? (
                    <SidebarItemCollapse item={route} key={index} />
                  ) : (
                    <SidebarItem item={route} key={index} />
                  )
                ) : null,
              )
          : null}
      </div>
      <div
        style={{
          textAlign: 'center',
          position: 'absolute',
          bottom: '0px',
          backgroundColor: '#1e293a',
          zIndex: '99',
          width: '100%',
        }}
      >
        <span style={{ fontSize: '0.7rem' }}>
          LS2i &copy; 2023 {t('footer.rights')} - {packageInfo.version}
        </span>
      </div>
    </CSidebar>
  )
}

export default AppSidebar
