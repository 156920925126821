import baseAxios from './baseAxios'
import i18n from 'src/i18n'
import { endpointsObj } from './sf/endpoints'
import { ToastMessage } from 'primereact/toast'

export const lifeToastSuccess = 3000
export const lifeToastWarn = 10000
export const lifeToastFailed = 30000

export interface endpointCallInterface {
  response: any
  success: boolean
  toast: ToastMessage
}

/**
 *
 * @param {Object} values {baseURL: URL de base, method:méthode de la requête, url:chemin de l'endpoint data:données à envoyer (optionnel)}
 * @param {function} setIsLoading {fonction mettant à jour une variable réactive indiquant l'état de l'appel API}
 * @returns {Object} {response:réponse de l'API, success:boléen true si le code de retour = 2xx et false sinon, toast:contenu de l'objet toast à afficher}
 */

export async function endpointCall(
  values: endpointsObj,
  setIsLoading?: Function,
): Promise<endpointCallInterface> {
  let returnObject = {
    response: {},
    success: false,
    toast: {} as ToastMessage,
  } //objet retourné
  let axiosObj = {
    method: values.method,
    url: values.url,
    baseURL: values.baseURL,
    data: values.data ? values.data : null,
    public: values.public,
  }

  if (setIsLoading) {
    setIsLoading(true)
  }
  try {
    const response = await baseAxios(axiosObj)
    if (response.status >= 200 && response.status < 300) {
      returnObject = {
        response: response,
        success: true,
        toast: {
          severity: 'success',
          summary: i18n.t('endpointCall.successMessage', {
            ns: 'endpointCall',
          }),
          life: lifeToastSuccess,
        },
      }
    }
  } catch (error: any) {
    if (error.response) {
      let ErrorMessage = ''
      switch (error.response.status) {
        case 400:
          // ErrorMessage = i18n.t('invalidInput', {
          //   ns: 'endpointCall',
          // })
          ErrorMessage = i18n.t('endpointCall.clientError', {
            ns: 'endpointCall',
          })
          break
        case 401:
          if (
            error.response.data.message &&
            error.response.data.message.includes('Invalid credential')
          ) {
            //identifiants de connexion invalides
            ErrorMessage = i18n.t('endpointCall.invalidCredentials', {
              ns: 'endpointCall',
            })
          } else if (
            error.response.data.message &&
            error.response.data.message.includes(
              'Your user account no longer exists',
            )
          ) {
            //identifiants de connexion invalides
            ErrorMessage = i18n.t('endpointCall.inactiveAccount', {
              ns: 'endpointCall',
            })
          } else {
            //JWT expiré
            console.error('401 : impossible normalement')
            ErrorMessage = i18n.t('endpointCall.unauthorized', {
              ns: 'endpointCall',
            })
          }
          break
        case 403:
          ErrorMessage = i18n.t('endpointCall.clientError', {
            ns: 'endpointCall',
          })
          break
        case 404:
          // ErrorMessage = i18n.t('notFound', {
          //   ns: 'endpointCall',
          // })
          ErrorMessage = i18n.t('endpointCall.clientError', {
            ns: 'endpointCall',
          })
          if (values.method === 'get') {
            window.location.href = '/error-page'
          }
          break
        case 422:
          // ErrorMessage = i18n.t('unprocessableEntry', {
          //   ns: 'endpointCall',
          // })
          ErrorMessage = i18n.t('endpointCall.clientError', {
            ns: 'endpointCall',
          })
          break
        case 500:
          //notifier erreur serveur
          // ErrorMessage = i18n.t('serverError', {
          //   ns: 'endpointCall',
          // })
          ErrorMessage = i18n.t('endpointCall.defaultError', {
            ns: 'endpointCall',
          })
          break
        default:
          // ErrorMessage = i18n.t('generalError', {
          //   ns: 'endpointCall',
          // })
          ErrorMessage = i18n.t('endpointCall.defaultError', {
            ns: 'endpointCall',
          })
          console.error(
            'On ne devrait pas être la, code http inattendu : ',
            error.response,
          )
      }
      returnObject = {
        success: false,
        response: error,
        toast: {
          severity: 'warn',
          detail: ErrorMessage,
          life: lifeToastFailed,
        },
      }
    } else if (error.request) {
      //notifier l'erreur
      console.error('Pas de réponse à la requete: ', error.request)
      returnObject = {
        success: false,
        response: error,
        toast: {
          severity: 'warn',
          detail: i18n.t('endpointCall.defaultError', {
            ns: 'endpointCall',
          }),
          // detail: i18n.t('requestError', {
          //   ns: 'endpointCall',
          // }),
          life: lifeToastFailed,
        },
      }
    } else {
      console.error('erreur axios non repertoriée: ', error)
      returnObject = {
        success: false,
        response: error,
        toast: {
          severity: 'warn',
          detail: i18n.t('endpointCall.axiosErrorNotListed', {
            ns: 'endpointCall',
          }),
          life: lifeToastFailed,
        },
      }
    }
  } finally {
    if (setIsLoading) {
      setIsLoading(false)
    }
  }
  return returnObject
}
