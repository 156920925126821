import { Route, Routes } from 'react-router-dom'
import NonExistingPage from 'src/views/nonExistingPage/NonExistingPage'
import RootPage from 'src/components/rootPage/RootPage'
import { generateRoute } from './GenerateRoutes'
import { useContext } from 'react'
import { RouterContext } from 'src/store/RouterContext'

function RoutesCollection(props: any) {
  const routes = generateRoute(useContext(RouterContext).routes!)

  return (
    <Routes>
      <Route path="*" element={<NonExistingPage />} />
      <Route path={'/'} element={<RootPage />} />
      <Route path="/">{routes}</Route>
    </Routes>
  )
}

export default RoutesCollection
