import {
  manageMercureSubscriptions,
  savedEventSourceCollection,
  savedEventSourceCollectionPermanent,
} from './manageMercureSubscriptions'
import { useContext, useEffect, useRef, useState } from 'react'
import {
  MercureReconnectionManager,
  actionError,
} from './MercureReconnectionManager'
import { subscribeToMercureTopic } from './subscribeToMercureTopic'
import { useSelector } from 'react-redux'
import { StateInterface } from 'src/typesPerso/State'
import { useDispatch } from 'react-redux'
import { increment, reset } from 'src/store/features/mercure/mercureSlice'
import { clearAllMercureSubscriptions } from './clearAllMercureSubscriptions'
import {
  incrementNbMercureAutoReconnectionAttempt,
  resetNbMercureAutoReconnectionAttempt,
} from 'src/store/features/mercure/mercureAutoReconnectionSlice'
import { AuthContext } from 'src/store/authContext'

export interface functionType {
  [key: string]: Function
}

export interface lastEventIdObjectType {
  [key: string]: string
}

//variables concernant les reconnexions automatiques
const maxAutomaticReconnections = 30

//nb max de tentatives de connexion
const maxAttempt = 3

//objet qui stocke les lastEventId {topic : lastEventId}
export let lastEventIDStorage: lastEventIdObjectType = {}
export function resetLastEventIDStorage() {
  lastEventIDStorage = {}
}

//objet contenant toutes les fonctions onMessages associées aux topics {topic:fonction}
let onMessageFunctions: functionType = {}
export function resetOnMessageFunctions() {
  onMessageFunctions = {}
}

//IDÉE : créer un seul objet qui contiendrait :
// - en clé le topic
// - en valeur : un objet
//    {
//      eventSource:l'event source,
//      permanent : booléen qui indique si le topic est permanent,
//      onMessageFunction : la fonction associée au onMessage pour ce topic
//      lastEventId : le last-event-id du dernier message reçu sur ce topic
//    }

/**
 * Ce hook gère la partie mercure.
 * Il met à jour le tableau des eventSources (supprime les inutiles et ajoute les manquants).
 * Il écoute ensuite chaque topic et pour chaque onMessage exécute la fonction correspondante.
 * @param {object} functions objet de fonction à exécuter pour chaque onMessage {"topic1":fonction1, "topic2":fonction2}
 * @param {boolean} arePermanentTopics indique si les topics sont permanents ou pas
 * @param {Object} toast objet de type toast (primeReactComponent) pour afficher une erreur si trop de tentatives de reconnexion
 */

export const useMercureSubscriptionsManager = (
  functions: functionType,
  arePermanentTopics: boolean,
  toast: any,
) => {
  const isMercureDisconnected = useRef(false)
  const [isTokenRefreshed, setIsTokenRefreshed] = useState(false) //état du token mercure
  const isTokenRefreshedCpy = useRef(false)
  const mercureNbAttempt = useSelector(
    (state: StateInterface) => state.mercure.nbMercureAttempt,
  ) // valeur du nombre de tentatives de reconnexions
  const mercureNbAttemptAutoReconnection = useSelector(
    (state: StateInterface) =>
      state.mercureAutoReconnection.nbMercureAutoReconnectionAttempt,
  ) // valeur du nombre de tentatives de reconnexions automatiques
  const dispatch = useDispatch()

  const { logout } = useContext(AuthContext) //fonction pour déconnecter l'utilisateur

  //suppression si on a une clé (=topic) vide
  //Remplissage de l'objet onMessageFunctions
  for (const key in functions) {
    if (key === '') {
      delete functions[key]
    } else {
      onMessageFunctions[key] = functions[key]
    }
  }

  const topics = Object.keys(functions) //on extrait les topics

  /**
   * Cette fonction gère un eventSource.
   * Elle va définir les événements onOpen, onMessage et onError
   * onMessage : on appelle la fonction passée en paramètre avec les données sous forme de valeur js
   * onError : gère la reconnexion
   * @param {string} topic le topic concerné
   * @param {EventSource} eventSource son eventSource associé
   */
  const ManageEventSource = (topic: string, eventSource: EventSource) => {
    //récupère l'eventsource associé au topic
    eventSource.onopen = (e: any) => {
      console.log('onopen : \ntopic : ', topic, '\n eventSource : ', e)
      if (mercureNbAttemptAutoReconnection > 0) {
        //on réinitialise le compteur de reconnexion automatiques
        dispatch(resetNbMercureAutoReconnectionAttempt())
      }
      if (mercureNbAttempt > 0) {
        //on réinitialise le compteur de reconnexion
        dispatch(reset())
      }
      if (isTokenRefreshedCpy.current === true) {
        setIsTokenRefreshed(false)
        isTokenRefreshedCpy.current = false
      }
      if (isMercureDisconnected.current === true) {
        //indique que mercure n'est plus déconnecté
        isMercureDisconnected.current = false
      }
    }
    eventSource.onmessage = (e: any) => {
      //mise à jour du lastEventID
      lastEventIDStorage[topic] = e.lastEventId

      console.log('onmessage : \ntopic : ', topic, '\n eventSource : ', e)
      //si on a des données on appelle la fonction passée en paramètre
      if (e.data) {
        // console.log(
        //   'topic : ',
        //   topic,
        //   '\nmessage mercure : ',
        //   JSON.parse(e.data),
        //   '\n eventSource : ',
        //   e
        // )
        onMessageFunctions[topic](JSON.parse(e.data))
      }
    }
    eventSource.onerror = async (e: any) => {
      console.log(
        'onerror :  \nReadyState : ',
        eventSource.readyState,
        '\ntopic : ',
        topic,
        '\nError event',
        e,
      )
      if (eventSource.readyState === 0) {
        if (mercureNbAttemptAutoReconnection < maxAutomaticReconnections) {
          console.log(
            'Tentatives de reconnexion auto : ',
            mercureNbAttemptAutoReconnection,
          )

          //on incrémente le compteur de reconnection si readyStateNull
          dispatch(incrementNbMercureAutoReconnectionAttempt())
        } else {
          console.log(
            'Top de reconnexion auto : ',
            mercureNbAttemptAutoReconnection,
          )
          //affichage d'un message : problème réseau, recharger la page
          actionError(toast)
          clearAllMercureSubscriptions()
        }
      }
      if (eventSource.readyState === 2) {
        console.log('dans le if readyState 2')
        //on est déconnecté de mercure
        if (
          mercureNbAttemptAutoReconnection < 30 &&
          !isMercureDisconnected.current
        ) {
          //on indique qu'on est déconnecté
          isMercureDisconnected.current = true
          //on appelle la fonction qui gère la reconnexion
          console.log('Tentatives de reconnexion : ', mercureNbAttempt)
          if (mercureNbAttempt >= maxAttempt) {
            console.log(
              'Tentatives de reconnexion max atteint: ',
              mercureNbAttempt,
            )
            //Le nombre max de tentatives est atteint
            //On demande à l'utilisateut de recharger la page
            //On ferme tous les EventSources
            actionError(toast)
            clearAllMercureSubscriptions()
          } else {
            dispatch(increment())
            const isReconnectionOK = await MercureReconnectionManager(
              setIsTokenRefreshed,
              toast,
            )
            if (!isReconnectionOK) {
              //le processus de reconnexion n'a pas fonctionné, on déconnecte l'utilisation
              logout()
            }
          }
        }
      }
    }
  }
  //useEffect qui s'effectueau lancement : créée les eventSources
  useEffect(() => {
    manageMercureSubscriptions(topics, arePermanentTopics)
    const collection = arePermanentTopics
      ? savedEventSourceCollectionPermanent
      : savedEventSourceCollection
    for (const [topic, es] of Object.entries(collection)) {
      ManageEventSource(topic, es)
    }
    const handleBeforeUnload = () => {
      // L'événement unload se déclenche lorsque la page est en cours de rechargement.
      // Ferme proprement les eventSource Mercure pour empêcher le actionError de se déclencher au rechargement
      clearAllMercureSubscriptions()
    }

    window.addEventListener('beforeunload', handleBeforeUnload)
    return () => {
      // Supprimez les gestionnaires d'événements lorsque le composant est démonté.
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
    // eslint-disable-next-line
  }, [topics])

  //UseEffect appelé lors du renouvellement du JWS
  useEffect(() => {
    if (isTokenRefreshedCpy.current !== isTokenRefreshed) {
      isTokenRefreshedCpy.current = isTokenRefreshed
    }
    if (isTokenRefreshed === true) {
      console.log(
        'UE, renouvelement du JWS effectué, on relance les eventSources',
      )

      for (const key in savedEventSourceCollection) {
        const eS = subscribeToMercureTopic(key)
        savedEventSourceCollection[key] = eS
      }

      for (const key in savedEventSourceCollectionPermanent) {
        const eS = subscribeToMercureTopic(key)
        savedEventSourceCollectionPermanent[key] = eS
      }

      for (const [topic, es] of Object.entries(savedEventSourceCollection)) {
        ManageEventSource(topic, es)
      }
      for (const [topic, es] of Object.entries(
        savedEventSourceCollectionPermanent,
      )) {
        ManageEventSource(topic, es)
      }
    }
    // eslint-disable-next-line
  }, [isTokenRefreshed])
}
