import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
//import i18n from 'src/i18n'
import { getSelectedLanguage } from 'src/utils/config/language'

export interface languageState {
  language: string | null
}

//si la langue fait partie des langues gérées, on retourne cette langue
//sinon on retourne le français
const initialState: languageState = {
  language: getSelectedLanguage(),
  // language: i18n.language,
}

export const languageSlice = createSlice({
  name: 'lang',
  initialState,
  reducers: {
    setLanguage: (state, action: PayloadAction<string>) => {
      state.language = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setLanguage } = languageSlice.actions

export default languageSlice.reducer
