import { RouteInterface } from 'src/typesPerso/Route'
//import { getUserRoles } from '../helpers'
//import { publicRoutes } from './publicRoutes/publicRoutes'

/**
 * Cettte fonction retourne les routes accessibles par l'utilisateur courant
 * @param {Array} routes ensemble des routes de l'application
 * @returns {Array} uniquement les routes accessibles par l'utilisateur
 */
export function getAllowedRoutes(
  routes: Array<RouteInterface>,
): Array<RouteInterface> | undefined {
  return routes
  // if (routes) {
  //   //Récupération de la liste des roles de l'utilisateur
  //   const roles = getUserRoles()
  //   if (roles) {
  //     //Si il y a le role admin retourne toutes les routes
  //     if (roles!.includes('ROLE_ADMIN')) {
  //       return routes
  //     } else {
  //       const tmpRoutes: Array<RouteInterface> = []
  //       //on parcours les routes
  //       routes.forEach((route) => {
  //         if (route.children) {
  //           //la route a des enfants
  //           if (!route.permissions) {
  //             //elle n'a pas de permission, on l'ajoute au tableau et on rapelle la fonction avec ses enfants
  //             const routeCpy = Object.assign({}, route)
  //             tmpRoutes.push(routeCpy)
  //             routeCpy.children = getAllowedRoutes(route.children)
  //           } else {
  //             //La route a une permission, on vérifie si l'utilisateur a accès
  //             //si oui on l'ajoute au tableau et on rapelle la fonction avec ses enfants
  //             //roles!.forEach((r) => {
  //             for (const r of roles) {
  //               if (route.permissions && route.permissions.includes(r)) {
  //                 const routeCpy = Object.assign({}, route)
  //                 tmpRoutes.push(routeCpy)
  //                 routeCpy.children = getAllowedRoutes(route.children!)
  //                 break
  //               }
  //             }
  //             //})
  //           }
  //         } else {
  //           //La route n'a pas d'enfants
  //           if (!route.permissions) {
  //             //la route n'a pas de permissions, on l'ajoute
  //             tmpRoutes.push(route)
  //           }
  //           //On filtre les routes suivant les roles de l'utilisateur
  //           else {
  //             for (const r of roles) {
  //               // roles!.forEach((r) => {
  //               if (route.permissions && route.permissions.includes(r)) {
  //                 tmpRoutes.push(route)
  //                 break
  //               }
  //             }
  //             //})
  //           }
  //         }
  //       })
  //       return tmpRoutes
  //     }
  //   } else {
  //     //Si utilisateur non connecté on retourne les routes sans permissions
  //     return publicRoutes
  //   }
  // }
}
