import * as browserStorage from './config/browserStorageNames'

export const getToken = () => {
  return sessionStorage.getItem(browserStorage.tokenItem)
}
export const setToken = (token: string) => {
  return sessionStorage.setItem(browserStorage.tokenItem, token)
}
export const removeToken = () => {
  return sessionStorage.removeItem(browserStorage.tokenItem)
}

export const getFirstname = () => {
  return sessionStorage.getItem(browserStorage.firstnameItem)
}
export const setFirstname = (firstname: string) => {
  return sessionStorage.setItem(browserStorage.firstnameItem, firstname)
}
export const removeFirstname = () => {
  return sessionStorage.removeItem(browserStorage.firstnameItem)
}

export const getLastname = () => {
  return sessionStorage.getItem(browserStorage.lastnameItem)
}
export const setLastname = (lastname: string) => {
  return sessionStorage.setItem(browserStorage.lastnameItem, lastname)
}
export const removeLastname = () => {
  return sessionStorage.removeItem(browserStorage.lastnameItem)
}

export const getPayload = () => {
  return sessionStorage.getItem(browserStorage.payloadItem)
}
export const setPayload = (payload: string) => {
  return sessionStorage.setItem(browserStorage.payloadItem, payload)
}
export const removePayload = () => {
  return sessionStorage.removeItem(browserStorage.payloadItem)
}

//Nettoyer le sessionStorage lors de la déconnexion
//Excepté la clé de la langue
export const clearStorage = () => {
  const lng = sessionStorage.getItem('i18nextLng')
  sessionStorage.clear()
  if (lng) {
    sessionStorage.setItem('i18nextLng', lng)
  }
}
