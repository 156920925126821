import { getUserRoles } from '../helpers'
import { deepCopy } from '../deepCopy'
import { RouteInterface } from 'src/typesPerso/Route'

//On passe un tableau de route de navigation (ayant une propriété permission), on retourne un tableau de route filtré par rapport aux role de l'utilisateur
export function getAllowedNavigation(routes: RouteInterface[]) {
  const routesCpy: Array<RouteInterface> = deepCopy(routes)
  //return routes
  //Récupération de la liste des roles de l'utilisateur
  const roles = getUserRoles()
  if (roles) {
    //Si il y a le role admin retourne toutes les routes
    if (roles!.includes('ROLE_ADMIN')) {
      return routesCpy
    }
    //Filtre des routes suivant les roles utilisateurs
    return routesCpy.filter((route) => {
      //On filtre les routes enfants
      if (route.children) {
        route.children = getAllowedNavigation(route.children)
      }
      //Si pas de permission, on retourne la route
      if (!route.permissions) return true
      //On filtre les routes suivant les roles de l'utilisateur
      else return roles!.some((r) => route.permissions!.includes(r))
    })
  }
}
