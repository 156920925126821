import { jwtDecode } from 'jwt-decode'
import { redirect } from 'react-router-dom'
import { TokenInterface } from 'src/typesPerso/Token'
import {
  darkModeItem,
  timeZoneItem,
  tokenItem,
  userItem,
} from './config/browserStorageNames'

//Fonction qui retourne le jwt stocké
export const getJwt = (): string | null => {
  return sessionStorage.getItem(tokenItem)
}

//Fonction qui retourne la date d'expiration du jwt
export const getExp = (): number | null => {
  const jwt = getJwt()
  if (typeof jwt === 'string') {
    return jwtDecode<TokenInterface>(jwt).exp
  } else return null
}

//Fonction qui retourne l'id de l'utilisateur courant
export const getUserId = (): number | null => {
  const jwt = getJwt()
  if (typeof jwt === 'string') {
    return jwtDecode<TokenInterface>(jwt).userId
  } else {
    return null
  }
}

//Fonction qui retourne le prénom d'utilisateur stocké dans le jwt
export const getUserFirstname = (): string | null => {
  const jwt = sessionStorage.getItem(tokenItem)
  if (jwt !== null) {
    return jwtDecode<TokenInterface>(jwt).firstname
  } else {
    return null
  }
}

//Fonction qui retourne le nom d'utilisateur stocké dans le jwt
export const getUserLastname = (): string | null => {
  const jwt = sessionStorage.getItem(tokenItem)
  if (jwt !== null) {
    return jwtDecode<TokenInterface>(jwt).lastname
  } else {
    return null
  }
}

/**
 * Fonction retournant les rôles de l'utilisateur
 * @returns tableau des rôles ou null
 */
export const getUserRoles = (): Array<string> | null => {
  const jwt = sessionStorage.getItem(tokenItem)
  if (typeof jwt === 'string') {
    return jwtDecode<TokenInterface>(jwt).roles
  } else {
    return null
  }
}

export const getRole = () => {
  if (getUser()) {
    const roles = getUserRoles()
    if (roles !== null) {
      const role =
        roles.find((role) => role === 'ROLE_ADMIN') !== undefined
          ? 'ROLE_ADMIN'
          : 'ROLE_USER'
      return role
    } else {
      return null
    }
  } else {
    return null
  }
}

export const setUser = (user: string) => {
  sessionStorage.setItem(userItem, user)
}

export const getUser = () => {
  return sessionStorage.getItem(userItem)
}

/**
 * Fonction permettant de rediriger un utilisateur authentifié
 * @returns redirection ou null
 * //TODO: modifier les redirections
 */
export function redirectIfUserIsLogged() {
  const token = getJwt()

  //Si l'utilisateur est authentifié, redirection vers le profil
  if (token) {
    // const userdatas = jwtDecode(token)
    return redirect('/contacts')
  }

  //la fonction doit avoir une valeur de retour si le token est présent. Ici nous n'avons pas besoin de valeur spécifique, le retour est null
  return null
}

//Retourne si le mode dark est activé dans le session storage
export const getDarkModeEnabled = () => {
  return sessionStorage.getItem(darkModeItem) === 'true' ? true : false
  // return sessionStorage.getItem("darkModeEnabled")
}

//Stocke si le mode dark est activé dans le session storage
export const setDarkModeEnabled = (darkMode: boolean) => {
  return sessionStorage.setItem(darkModeItem, darkMode.toString())
}

//Retourne la time zone choisie
export const getTimeZone = () => {
  return sessionStorage.getItem(timeZoneItem)
  // return sessionStorage.getItem("darkModeEnabled")
}

////Stocke la time zone choisie
export const setTimeZone = (timeZone: string) => {
  return sessionStorage.setItem(timeZoneItem, timeZone)
}
