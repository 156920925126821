import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { defaultLanguage, supportedLanguages } from './utils/config/language'

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false
  }
}

const DETECTION_OPTIONS = {
  order: ['sessionStorage', 'navigator'],
  caches: ['sessionStorage'], //add var in sessionStorage
}

let backendConfig = {
  // Configuration par défaut à utiliser en dehors de l'environnement de développement
}

if (process.env.NODE_ENV === 'development') {
  backendConfig = {
    // Configuration spécifique à utiliser dans l'environnement de développement
    loadPath:
      //      '/en' +
      // process.env.REACT_APP_BASE_SEGMENT_URL +
      '/locales/{{lng}}/{{ns}}.json',
  }
}

i18n
  // load translation using http -> see /public/locales
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    partialBundledLanguages: true, //chargement partiel des ressources. Celles présentes dans le ns sont chargées initialements
    detection: DETECTION_OPTIONS,
    debug: false,
    supportedLngs: supportedLanguages, //langues supportées par l'application
    nonExplicitSupportedLngs: true, //if true, will consider variants as supported when the main language is. E.g. en-US will be valid if en is in supportedLngs
    fallbackLng: defaultLanguage, //language to use if translations in user language are not available
    backend: backendConfig,
    ns: [
      'translation',
      'endpointCall',
      'colors',
      'genders',
      'mailTypes',
      'phoneTypes',
      'secondaryActions',
      'errorsMessages',
    ],
    //lng: 'fr', //par défaut l'application est en français
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  })

export default i18n as typeof i18n
