import { DataTableSortMeta } from 'primereact/datatable'

/**
 * Ce fichier contient la liste des endpoints pour la partie authentification
 * Chaque fonction correspond à un endpoint et une méthode. On passe éventuellement en paramètre les données à envoyer
 * et/ou des données pouvant servir pour créer le chemin
 * Pour chaque action, un objet est retourné sous la forme
 * {
 *    method:méthode http (get, post, ...)
 *    url:chemin de l'endpoint
 *    data:les données à envoyer (optionnel)
 *    public:booléen indiquant si l'URL est publique (pas de JWT)(optionnel)
 * }
 */
let baseURL = ''
if (process.env.REACT_APP_SF_USER_API) {
  baseURL = process.env.REACT_APP_SF_USER_API
} else {
  //FIXME: erreur dans le .env
}

export interface endpointsObj {
  method: string
  url: string
  baseURL: string
  data?: object
  public?: boolean
}

export const login = (data: object): endpointsObj => {
  return {
    baseURL: baseURL,
    method: 'post',
    url: '/login',
    data: data,
    public: true,
  }
}

/**
 * Renouvellement du JWT
 */
export const refreshToken = (): endpointsObj => {
  return {
    baseURL: baseURL,
    method: 'post',
    url: '/token/refresh',
    data: {},
    public: true,
  }
}

/**
 * Récupérer la liste des utilisateurs
 */
export const getUsers = (): endpointsObj => {
  return { baseURL: baseURL, method: 'get', url: '/users' }
}

//Récupération des utilisateurs avec pagniation serveur
//page : numéro de la page, itemsPerPage: nombre de lignes par page
export const getUsersPagination = (params: {
  page: number
  itemsPerPage: number
  q?: string
  orderSort?: Array<DataTableSortMeta>
  active?: boolean | null
}): endpointsObj => {
  const queries = new URLSearchParams()
  queries.append('page', params.page.toString())
  queries.append('itemsPerPage', params.itemsPerPage.toString())
  if (params.q) {
    queries.append('q', params.q)
  }
  if (params.orderSort) {
    params.orderSort.forEach((p) => {
      let columnName = p.field
      if (columnName === 'firstname') {
        columnName = 'person.firstname'
      }
      if (columnName === 'lastname') {
        columnName = 'person.lastname'
      }
      queries.append(
        'order[' + columnName + ']',
        p.order === 1 ? 'asc' : 'desc',
      )
    })
  }
  if (params.active === false || params.active === true) {
    queries.append('active', params.active.toString())
  }

  return {
    baseURL: baseURL,
    method: 'get',
    url: '/users?' + queries.toString(),
  }
}

/**
 * Récupérer les données d'un utilisateur
 * @param {number} id l'identifiant de l'utilisateur concerné
 */
export const getUser = (id: number) => {
  return { baseURL: baseURL, method: 'get', url: `/users/${id}` }
}

/**
 * Créer un utilisateur
 * @param {Object} data les données de l'utilisateur
 * {
 *    "email": "john.doe@example.com",
 *    "firstname": "John",
 *    "lastname": "Doe",
 * }
 */
export const createUser = (data: object) => {
  return { baseURL: baseURL, method: 'post', url: '/users', data: data }
}

/**
 * Mettre à jour un utilisateur
 * @param {Object} data les données à changer (seule les données qui diffèrent sont envoyées)
 * {
 *    "email": "john.doe@example.com" (optionnel),
 *    "firstname": "John" (optionnel),
 *    "lastname": "Doe" (optionnel),
 *    "roles": [role1, role2] (optionnel)
 * }
 * @param {number} id l'identifiant de l'utilisateur concerné
 */
export const updateUser = (data: object, userId: number | null) => {
  return {
    baseURL: baseURL,
    method: 'patch',
    url: `/users/${userId}`,
    data: data,
  }
}

/**
 * Mettre à jour plusieurs utilisateurs
 * @param {Object} users un tableau d'utilisateurs à modifier
 * {
 *    "id": identifiant de l'utilisateur,
 *    "active": propriété à modifier (ici pour activer ou désactiver l'utilisateur)
 * }
 */
export const updateUsers = (users: object) => {
  return {
    baseURL: baseURL,
    method: 'patch',
    url: `/users`,
    data: { users: users },
  }
}

/**
 * Récupérer la liste des rôles
 */
export const getRoles = () => {
  return { baseURL: baseURL, method: 'get', url: '/roles' }
}

/**
 * Vérifier le token de création de compte
 * L'utilisateur a cliqué sur le lien pour créer son compte
 * Au moment de l'ouverture de la page on appelle cette fonction pour vérifier la validité du token
 * @param {string} token token de création de compte (récupéré dans l'URL)
 */
export const initAccount = (token: string | undefined) => {
  return {
    baseURL: baseURL,
    method: 'get',
    url: `/users/init/account/${token}`,
    public: true,
  }
}

/**
 * Initialiser le mot de passe de l'utilisateur (finalisation de l'étape de création de compte)
 * L'utilisateur a cliqué sur le lien pour créer son compte et il doit maintenant saisir un mot de passe
 * @param {Object} data les données à mettre è jour, soit ici le mot de passe
 * {
  "token": "string",
  "password": "My-awesome-12-chars-password"
    }
 */
export const createAccount = (data: object) => {
  return {
    baseURL: baseURL,
    method: 'post',
    url: `/account_init`,
    data: data,
    public: true,
  }
}

/**
 * Renvoyer le mail d'activation
 * @param {number} id l'identifiant de l'utilisateur
 */
//TODO: ajouter au readme
export const sendActivationChallenge = (id: number) => {
  return {
    baseURL: baseURL,
    method: 'post',
    url: '/users/' + id + '/activation_challenge',
    data: {},
  }
}

/**
 * Demande de réinitisalisation de mot de passe
 * {
  "email": "user@example.com"
    }
 * @param {Object} data objet
 */
export const passwordReset = (data: object) => {
  return {
    baseURL: baseURL,
    method: 'post',
    url: `/password_reset`,
    data: data,
    public: true,
  }
}

/**
 * Réinitisalisation de mot de passe
 * {
  "token": "string",
  "password": "My-awesome-12-chars-password"
}
 * @param {Object} data objet
 */
export const passwordResetValidation = (data: object) => {
  return {
    baseURL: baseURL,
    method: 'post',
    url: `/password_reset_validation`,
    data: data,
    public: true,
  }
}

/**
 * Validation du changement d'email de l'utilisateur authentifié
 */
export const emailChangeValidation = (data: string, userId: number | null) => {
  const token = {
    token: data,
  }
  return {
    baseURL: baseURL,
    method: 'post',
    url: `users/${userId}/email_change_validation`,
    data: token,
  }
}

/**
 * Demande de changement d'email pour un utilisateur authentifié
 */
export const emailChange = (data: object, userId: number | null) => {
  return {
    baseURL: baseURL,
    method: 'post',
    url: `/users/${userId}/email_change`,
    data: data,
  }
}

/**
 * Récupérer le jwt mercure
 */
export const getMercureJWT = () => {
  return { baseURL: baseURL, method: 'get', url: '/token/mercure/subscriber' }
}

// //poster un devis
// export const postQuote = (files: File[], prospectId: number) => {
//   const formData = new FormData()

//   files.forEach((f: File) => {
//     const uploadedBlob = new Blob([f], {
//       type: f.type,
//     })
//     formData.append('file', uploadedBlob, f.name)
//   })

//   //console.log(formData)

//   return {
//     baseURL: baseURL,
//     method: 'post',
//     url: `/prospects/${prospectId}/quote`,
//     data: formData,
//   }
// }
