import { createSlice } from '@reduxjs/toolkit'

export interface mercureState {
  nbMercureAttempt: number
}

const initialState: mercureState = {
  nbMercureAttempt: 0,
}

export const mercureSlice = createSlice({
  name: 'mercure',
  initialState,
  reducers: {
    reset: (state) => {
      state.nbMercureAttempt = 0
      return state
    },
    increment: (state) => {
      state.nbMercureAttempt += 1
      return state
    },
  },
})

// Action creators are generated for each case reducer function
export const { reset, increment } = mercureSlice.actions

export default mercureSlice.reducer
