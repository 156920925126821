import { ReactNode } from 'react'
import { Route } from 'react-router-dom'
import PageWrapper from 'src/layout/protected/PageWrapper'
import { RouteInterface } from 'src/typesPerso/Route'

export const generateRoute = (routes: RouteInterface[]): ReactNode => {
  if (routes) {
    return routes.map((route, index) =>
      route.index ? (
        <Route
          index
          path={route.path}
          element={
            <PageWrapper permissions={route.permissions} state={route.state}>
              {route.element}
            </PageWrapper>
          }
          key={index}
        />
      ) : (
        <Route
          path={route.path}
          element={
            <PageWrapper
              permissions={route.permissions}
              state={route.children ? undefined : route.state}
            >
              {route.element}
            </PageWrapper>
          }
          key={index}
        >
          {route.children && generateRoute(route.children)}
        </Route>
      ),
    )
  }
}
