import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface sidebarState {
  isSidebarShown: boolean
}

const initialState: sidebarState = {
  isSidebarShown: true,
}

export const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    show: (state, action: PayloadAction<boolean>) => {
      state.isSidebarShown = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const sidebarActions = sidebarSlice.actions

export default sidebarSlice.reducer
