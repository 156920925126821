import { configureStore } from '@reduxjs/toolkit'
import sidebarSlice from './features/sidebar/sidebarSlice'
import languageSlice, { setLanguage } from './features/language/languageSlice'
import backdropShowSlice from './features/backdrop/backdropShowSlice'
import reloadComponentSlice from './features/reloadComponent/reloadComponentSlice'
import appStateSlice from './features/appState/appStateSlice'
import mercureSlice from './features/mercure/mercureSlice'
import mercureAutoReconnectionSlice from './features/mercure/mercureAutoReconnectionSlice'
import i18n from 'src/i18n'

export const store = configureStore({
  reducer: {
    sidebar: sidebarSlice,
    language: languageSlice,
    backdropShow: backdropShowSlice,
    reloadComponent: reloadComponentSlice,
    appState: appStateSlice,
    mercure: mercureSlice,
    mercureAutoReconnection: mercureAutoReconnectionSlice,
  },
  devTools:
    !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
      ? true
      : false,

  //  middleware: (getDefaultMiddleware) =>
  //   getDefaultMiddleware({
  //     serializableCheck: false,
  //   }),
})

// Écoutez l'événement 'initialized' de i18next
//dès que i18n a fini de s'initialiser on met à jour la langue
i18n.on('initialized', () => {
  store.dispatch(setLanguage(i18n.language.split('-')[0]))
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
