import React from 'react'
import { Suspense } from 'react'
import Loader from '../../components/loader/Loader'
import { publicRoutes } from './publicRoutes/publicRoutes'
import PubilcLayout from 'src/layout/public/PublicLayout'
import DefaultLayout from 'src/layout/protected/DefaultLayout'
import { icons } from '../config/icons'

//Profile page
const Profile = React.lazy(() => import('src/views/profile/Profile'))

//Routes admin
const Users = React.lazy(() => import('src/views/admin/users/Users'))

//Vues
const Clients = React.lazy(() => import('src/views/clients/Clients'))
const Client = React.lazy(() => import('src/views/clients/client/Client'))

const Prospects = React.lazy(() => import('src/views/prospects/Prospects'))
const Prospect = React.lazy(
  () => import('src/views/prospects/prospect/Prospect'),
)
const Contacts = React.lazy(() => import('src/views/contacts/Contacts'))
const Contact = React.lazy(() => import('src/views/contacts/contact/Contact'))

const ContactData = React.lazy(
  () => import('src/components/contact/contactData/ContactData'),
)
const Interactions = React.lazy(
  () => import('src/components/contact/interactions/Interactions'),
)
const History = React.lazy(
  () => import('src/components/contact/history/History'),
)
const Documents = React.lazy(
  () => import('src/components/contact/documents/DocumentsContact'),
)
const Quotes = React.lazy(() => import('src/components/quotesView/QuotesView'))

const Organizations = React.lazy(
  () => import('src/views/organizations/Organizations'),
)
const Organization = React.lazy(
  () => import('src/views/organizations/organization/Organization'),
)
const People = React.lazy(() => import('src/views/people/People'))

// const Documents = React.lazy(
//   () => import('src/views/admin/documents/Documents'),
// )
const Opportunities = React.lazy(
  () => import('src/views/opportunities/Opportunities'),
)

const Policies = React.lazy(() => import('src/views/policies/Policies'))
const Policy = React.lazy(() => import('src/views/policies/policy/Policy'))

const Tasks = React.lazy(() => import('src/views/tasks/Tasks'))

//Setting page
const Settings = React.lazy(() => import('src/views/settings/Settings'))

const ErrorPage = React.lazy(() => import('src/views/ErrorPage/ErrorPage'))

export const routes: any = [
  {
    name: 'Public',
    path: '',
    element: (
      <Suspense fallback={<Loader />}>
        <PubilcLayout />
      </Suspense>
    ),
    id: 'public',
    children: [
      {
        name: 'ErrorPage',
        path: '/error',
        state: 'error',

        element: <ErrorPage />,
      },
      ...publicRoutes,
    ],
  },

  {
    name: 'Layout',
    path: '',
    id: 'defaultLayout',
    element: (
      <Suspense fallback={<Loader />}>
        <DefaultLayout />
      </Suspense>
    ),
    permissions: ['ROLE_USER'],
    children: [
      {
        name: 'Profile',
        path: '/profile',
        state: 'profile',
        element: (
          <Suspense fallback={<Loader />}>
            <Profile />
          </Suspense>
        ),
        //loader: loaderProfile,
        permissions: ['ROLE_USER'],
      },
      {
        name: 'Settings',
        path: '/settings',
        state: 'settings',
        element: (
          <Suspense fallback={<Loader />}>
            <Settings />
          </Suspense>
        ),
        permissions: ['ROLE_USER'],
      },
      {
        name: 'Contacts',
        index: true,
        state: 'contacts',
        element: (
          <Suspense fallback={<Loader />}>
            <Contacts />
          </Suspense>
        ),
        permissions: ['ROLE_USER'],
      },
      {
        name: 'Contacts',
        sidebarProps: {
          displayText: 'CRM',
          mainMenu: true,
        },
        permissions: ['ROLE_USER'],
      },
      {
        name: 'Contacts',
        path: '/contacts',
        state: 'contacts',
        sidebarProps: {
          displayText: 'contacts',
          icon: icons.contact,
          style: 'menu1',
        },
        element: (
          <Suspense fallback={<Loader />}>
            <Contacts />
          </Suspense>
        ),

        permissions: 'ROLE_USER',
      },
      {
        name: 'Contact',
        path: '/contacts/:contactId',
        element: (
          <Suspense fallback={<Loader />}>
            <Contact />
          </Suspense>
        ),
        permissions: ['ROLE_USER'],
        children: [
          {
            name: 'ContactInteractions',
            path: '/contacts/:contactId/interactions',
            state: 'ContactInteractions',
            element: (
              <Suspense fallback={<Loader />}>
                <Interactions />
              </Suspense>
            ),
            permissions: ['ROLE_USER'],
          },
          {
            name: 'ContactData',
            path: '/contacts/:contactId/informations',
            state: 'contactData',
            element: (
              <Suspense fallback={<Loader />}>
                <ContactData />
              </Suspense>
            ),
            permissions: ['ROLE_USER'],
          },
          {
            name: 'ContactHistory',
            path: '/contacts/:contactId/history',
            state: 'ContactHistory',
            element: (
              <Suspense fallback={<Loader />}>
                <History />
              </Suspense>
            ),
            permissions: ['ROLE_USER'],
          },
          {
            name: 'ProspectHistory',
            path: '/contacts/:contactId/history_prospect/:prospectId',
            state: 'ProspectHistory',
            element: (
              <Suspense fallback={<Loader />}>
                <History />
              </Suspense>
            ),
            permissions: ['ROLE_USER'],
          },
          {
            name: 'ContactDocuments',
            path: '/contacts/:contactId/documents',
            state: 'ContactDocuments',
            element: (
              <Suspense fallback={<Loader />}>
                <Documents />
              </Suspense>
            ),
            permissions: ['ROLE_USER'],
          },
          {
            name: 'ContactQuotes',
            path: '/contacts/:contactId/quotes',
            state: 'ContactQuotes',
            element: (
              <Suspense fallback={<Loader />}>
                <Quotes />
              </Suspense>
            ),
            permissions: ['ROLE_USER'],
          },
        ],
      },
      {
        name: 'Prospects',
        path: '/prospects',
        state: 'prospects',
        sidebarProps: {
          displayText: 'prospects',
          icon: icons.prospect,
          style: 'menu1',
        },
        element: (
          <Suspense fallback={<Loader />}>
            <Prospects />
          </Suspense>
        ),

        permissions: ['ROLE_USER'],
      },
      {
        name: 'Prospect',
        path: '/prospects/:prospectId',
        element: (
          <Suspense fallback={<Loader />}>
            <Prospect />
          </Suspense>
        ),
        permissions: ['ROLE_USER'],
        children: [
          {
            name: 'ProspectInteractions',
            path: '/prospects/:prospectId/interactions',
            state: 'ProspectInteractions',
            element: (
              <Suspense fallback={<Loader />}>
                <Interactions />
              </Suspense>
            ),
            permissions: ['ROLE_USER'],
          },
          {
            name: 'ProspectInformations',
            path: '/prospects/:prospectId/informations',
            state: 'ProspectInformations',
            element: (
              <Suspense fallback={<Loader />}>
                <ContactData />
              </Suspense>
            ),
            permissions: ['ROLE_USER'],
          },
          {
            name: 'ProspectHistory',
            path: '/prospects/:prospectId/history',
            state: 'ProspectHistory',
            element: (
              <Suspense fallback={<Loader />}>
                <History />
              </Suspense>
            ),
            permissions: ['ROLE_USER'],
          },
          {
            name: 'ProspectDocuments',
            path: '/prospects/:prospectId/documents',
            state: 'ProspectDocuments',
            element: (
              <Suspense fallback={<Loader />}>
                <Documents />
              </Suspense>
            ),
            permissions: ['ROLE_USER'],
          },
          {
            name: 'ProspectQuotes',
            path: '/prospects/:prospectId/quotes',
            state: 'ProspectQuotes',
            element: (
              <Suspense fallback={<Loader />}>
                <Quotes />
              </Suspense>
            ),
            permissions: ['ROLE_USER'],
          },
        ],
      },
      {
        name: 'Opportunities',
        path: '/opportunities',
        state: 'opportunities',
        sidebarProps: {
          displayText: 'opportunities',
          icon: icons.opportunity,
          style: 'menu1',
        },
        element: (
          <Suspense fallback={<Loader />}>
            <Opportunities />
          </Suspense>
        ),

        permissions: 'ROLE_USER',
      },

      {
        name: 'ERP',
        sidebarProps: {
          displayText: 'erp',
          mainMenu: true,
        },
        permissions: ['ROLE_USER'],
      },
      {
        name: 'Clients',
        path: '/clients',
        state: 'clients',
        sidebarProps: {
          displayText: 'clients',
          icon: icons.client,
          style: 'menu1',
        },
        element: (
          <Suspense fallback={<Loader />}>
            <Clients />
          </Suspense>
        ),

        permissions: ['ROLE_USER'],
      },
      {
        name: 'Client',
        path: '/clients/:clientId',
        element: (
          <Suspense fallback={<Loader />}>
            <Client />
          </Suspense>
        ),
        permissions: ['ROLE_USER'],
        children: [
          {
            name: 'ClientInteractions',
            path: '/clients/:clientId/interactions',
            state: 'ClientInteractions',
            element: (
              <Suspense fallback={<Loader />}>
                <Interactions />
              </Suspense>
            ),
            permissions: ['ROLE_USER'],
          },
          {
            name: 'ClientInformations',
            path: '/clients/:clientId/informations',
            state: 'ClientInformations',
            element: (
              <Suspense fallback={<Loader />}>
                <ContactData />
              </Suspense>
            ),
            permissions: ['ROLE_USER'],
          },
          {
            name: 'ClientHistory',
            path: '/clients/:clientId/history',
            state: 'ClientHistory',
            element: (
              <Suspense fallback={<Loader />}>
                <History />
              </Suspense>
            ),
            permissions: ['ROLE_USER'],
          },
          {
            name: 'ClientDocuments',
            path: '/clients/:clientId/documents',
            state: 'ClientDocuments',
            element: (
              <Suspense fallback={<Loader />}>
                <Documents />
              </Suspense>
            ),
            permissions: ['ROLE_USER'],
          },
        ],
      },
      {
        name: 'Policies',
        path: '/policies',
        state: 'policies',
        sidebarProps: {
          displayText: 'policies',
          icon: icons.policies,
          style: 'menu1',
        },
        element: (
          <Suspense fallback={<Loader />}>
            <Policies />
          </Suspense>
        ),

        permissions: ['ROLE_USER'],
      },
      {
        name: 'Policy',
        path: '/policies/:policyId',
        element: (
          <Suspense fallback={<Loader />}>
            <Policy />
          </Suspense>
        ),
        permissions: ['ROLE_USER'],
      },
      {
        name: 'Entity',
        sidebarProps: {
          displayText: 'entities',
          mainMenu: true,
        },
        permissions: ['ROLE_USER'],
      },
      {
        name: 'Organizations',
        path: '/organizations',
        state: 'organizations',
        sidebarProps: {
          displayText: 'organizations',
          icon: icons.organization,
          style: 'menu1',
        },
        element: (
          <Suspense fallback={<Loader />}>
            <Organizations />
          </Suspense>
        ),

        permissions: ['ROLE_USER'],
      },
      {
        name: 'Organization',
        path: '/organizations/:organizationId',
        element: (
          <Suspense fallback={<Loader />}>
            <Organization />
          </Suspense>
        ),
        permissions: ['ROLE_USER'],
      },
      {
        name: 'People',
        path: '/people',
        state: 'people',
        sidebarProps: {
          displayText: 'people',
          icon: icons.people,
          style: 'menu1',
        },
        element: (
          <Suspense fallback={<Loader />}>
            <People />
          </Suspense>
        ),

        permissions: ['ROLE_USER'],
      },
      {
        name: 'Activities',
        sidebarProps: {
          displayText: 'myActivities',
          mainMenu: true,
        },
        permissions: ['ROLE_USER'],
      },
      {
        name: 'Tasks',
        path: '/tasks',
        state: 'tasks',
        sidebarProps: {
          displayText: 'tasks',
          icon: icons.task,
          style: 'menu1',
        },
        element: (
          <Suspense fallback={<Loader />}>
            <Tasks />
          </Suspense>
        ),

        permissions: ['ROLE_USER'],
      },
      {
        name: 'Admin',
        sidebarProps: {
          displayText: 'admin',
          mainMenu: true,
        },
        permissions: ['ROLE_ADMIN'],
      },
      {
        name: 'Users',
        path: '/admin/users',
        state: 'admin.users',
        sidebarProps: {
          displayText: 'users',
          icon: icons.users,
        },
        element: (
          <Suspense fallback={<Loader />}>
            <Users />
          </Suspense>
        ),
        permissions: ['ROLE_ADMIN'],
      },
    ],
  },
]
