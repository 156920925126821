import React, { Suspense, useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'
import './scss/style.scss'
import Loader from './components/loader/Loader'
import packageInfo from '../package.json'
import { CacheBuster } from 'react-cache-buster/dist/CacheBuster'
import { StyledEngineProvider } from '@mui/material'
import RoutesCollection from './utils/routes/RoutesCollection'
import i18n from './i18n'
import { store } from './store/store'
import { setLanguage } from './store/features/language/languageSlice'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/fr'
import 'dayjs/locale/en'
import { StateInterface } from './typesPerso/State'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import { locales } from './utils/config/locales'

const App = () => {
  const version = packageInfo.version

  //récupération de la langue
  const language = useSelector(
    (state: StateInterface) => state.language.language,
  ) // store du langage

  // //ajuste les paramètres de dayjs
  const dayjsOptions = () => {
    //const locale = language.split('-')[0]
    dayjs.extend(utc)
    dayjs.extend(timezone) //fuseau horaire   //TODO: provoque les deux années 1900 du datepicker de mui
    dayjs.extend(localizedFormat) //utilise les formatage de daysjs
    dayjs.locale(language) //afficher les dates selon la langue
  }

  //useEffect qui s'effectue au lancement et au changement de langue
  //met à jour les options de dayjs
  useEffect(() => {
    dayjsOptions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language])

  //useEffect qui s'effectue au lancement
  //lance une écoute sur l'événement "la page est rechargée" et la langue est changée
  useEffect(() => {
    const handleBeforeUnload = () => {
      // L'événement unload se déclenche lorsque la page est en cours de rechargement.
      //remet dans le sessionStorage la langue de i18n (au cas où l'utilisateur aurait changé cette valeur)
      sessionStorage.setItem('i18nextLng', i18n.language)
    }

    //Au changement de langue : on met à jour le store
    i18n.on('languageChanged', function (lng) {
      store.dispatch(setLanguage(lng))
    })

    //Écoute l'événement "rechargement de la page"
    window.addEventListener('beforeunload', handleBeforeUnload)
    return () => {
      // Supprimez les gestionnaires d'événements lorsque le composant est démonté.
      window.removeEventListener('beforeunload', handleBeforeUnload)
      i18n.off('languageChanged')
    }
    // eslint-disable-next-line
  }, [])

  return (
    <StyledEngineProvider injectFirst>
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale={language}
        localeText={locales[language as keyof typeof locales]}
      >
        <CacheBuster
          currentVersion={version}
          reloadOnDowngrade={true}
          // onCacheClear={() => {
          //   window.location.reload(true);
          // }}
          isEnabled={true} //If false, the library is disabled.
          isVerboseMode={false} //If true, the library writes verbose logs to console.
          loadingComponent={<Loader />} //If not pass, nothing appears at the time of new version check.
          metaFileDirectory={'.'} //If public assets are hosted somewhere other than root on your server.
        >
          <Suspense fallback={<Loader />}>
            <BrowserRouter>
              <RoutesCollection />
            </BrowserRouter>
          </Suspense>
        </CacheBuster>
      </LocalizationProvider>
    </StyledEngineProvider>
  )
}

export default App
