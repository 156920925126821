import 'react-app-polyfill/stable'
import 'core-js'
import { createRoot } from 'react-dom/client'
import App from './App'
import { Provider } from 'react-redux'
import { store } from './store/store'
import { I18nextProvider } from 'react-i18next'
import i18n from './i18n'
import 'primereact/resources/themes/lara-light-indigo/theme.css' //theme
import 'primereact/resources/primereact.min.css' //core
import 'primeicons/primeicons.css' //icons
import { AuthContextProvider } from './store/authContext'
import { RouterContextProvider } from './store/RouterContext'
import './theme/theme.css'
import './index.css'
import 'animate.css'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import { AnimationContextProvider } from './store/animationContext'
import { ThemeProvider, createTheme } from '@mui/material/styles'

export const theme = createTheme({
  palette: {
    primary: {
      main: '#01002D', // votre couleur primaire souhaitée
      contrastText: '#fff',
      dark: '#006064',
    },
  },
})

createRoot(document.getElementById('root')!).render(
  <I18nextProvider i18n={i18n}>
    <AuthContextProvider>
      <RouterContextProvider>
        <AnimationContextProvider>
          <Provider store={store}>
            <ThemeProvider theme={theme}>
              <App />
            </ThemeProvider>
          </Provider>
        </AnimationContextProvider>
      </RouterContextProvider>
    </AuthContextProvider>
  </I18nextProvider>,
)
