import { CCol, CContainer, CRow } from '@coreui/react'
import { useNavigate } from 'react-router-dom'
import { Divider } from 'primereact/divider'
import { useTranslation } from 'react-i18next'
import BackButton from 'src/components/UI/BackButton'

/**
 * Composant
 * page d'erreur d'une route nécessitant une authentification
 * retourne une page d'erreur pour informer l'utilisateur qu'il doit être connecté
 * @return {jsx element}
 */
const UnauthorizePage = () => {
  // Init react-i18next localisation
  const { t } = useTranslation(['unauthorizePage'])

  const navigate = useNavigate()

  return (
    <div className=" ">
      <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
        <CContainer>
          <CRow className="justify-content-center w-100">
            <CCol md={8} className="d-flex flex-column align-items-center">
              <div className="clearfix">
                <h1 className="display-3 text-center">
                  {t('unauthorizePage.titleUnauthorize')}
                </h1>
                <Divider />
                <p className="text-medium-emphasis text-center">
                  {t('unauthorizePage.unauthorizePhrase')}
                </p>
              </div>

              <BackButton
                onClick={() => navigate('/')}
                phrase={t('unauthorizePage.return')}
              />
            </CCol>
          </CRow>
        </CContainer>
      </div>
    </div>
  )
}

export default UnauthorizePage
