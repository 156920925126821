import { ThemeProvider, createTheme } from '@mui/material'
import { ReactNode, useContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { AuthContext } from 'src/store/authContext'
import { appStateActions } from 'src/store/features/appState/appStateSlice'
import { getUserRoles } from 'src/utils/helpers'
import UnauthorizePage from 'src/views/unauthorizePage/UnauthorizePage'

type Props = {
  state?: string
  children: ReactNode
  permissions: Array<string> | undefined
}

const PageWrapper = (props: Props) => {
  const roles = getUserRoles()

  const [isUserHaveAccess, setUserHaveAccess] = useState(false)

  if (props.permissions && props.permissions.length > 0 && roles) {
    const access = roles.some((role) => props.permissions!.includes(role))
    if (access !== isUserHaveAccess) setUserHaveAccess(access)
  }

  const dispatch = useDispatch()

  //récupération de la variable darkmode
  const { darkModeEnabledContext } = useContext(AuthContext) //Contexte application

  const [mode, setMode] = useState<'light' | 'dark'>(
    darkModeEnabledContext === true ? 'dark' : 'light',
  )

  let theme = createTheme({
    palette: {
      mode: mode,
    },
  })

  useEffect(() => {
    setMode(darkModeEnabledContext === true ? 'dark' : 'light')
    // eslint-disable-next-line react-hooks/exhaustive-deps
    theme = createTheme({
      palette: {
        mode: mode,
      },
      components: {
        MuiTabs: {
          styleOverrides: {
            root: {
              borderRadius: 10,
              minHeight: 44,
            },
          },
        },
        MuiPaper: {
          styleOverrides: {
            root: {
              background: mode === 'light' ? '#f7f7f7' : '#333',
            },
          },
        },
      },
    })
  }, [darkModeEnabledContext])

  //Code du site MUI, à voir
  // const theme = useMemo(
  //   () =>
  //     createTheme({
  //       palette: {
  //         mode,
  //       },
  //     }),
  //   [mode],
  // )

  useEffect(() => {
    if (props.state) {
      dispatch(appStateActions.set(props.state))
    }
    if (darkModeEnabledContext === true) {
      document.body.classList.add('dark-mode')
    } else {
      document.body.classList.remove('dark-mode')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, darkModeEnabledContext, props])

  return (
    <ThemeProvider theme={theme}>
      {!props.permissions ? (
        props.children
      ) : isUserHaveAccess ? (
        props.children
      ) : (
        <UnauthorizePage />
      )}
    </ThemeProvider>
  )
}

export default PageWrapper
