import {
  faAddressBook,
  faBuilding,
  faBullseye,
  faHandHoldingDollar,
  faHandshake,
  faMagnifyingGlassDollar,
  faPeopleGroup,
  faUsers,
  faCommentDollar,
  faBuildingShield,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const icons = {
  prospect: <FontAwesomeIcon icon={faMagnifyingGlassDollar} />,
  contact: <FontAwesomeIcon icon={faAddressBook} />,
  client: <FontAwesomeIcon icon={faHandshake} />,
  opportunity: <FontAwesomeIcon icon={faHandHoldingDollar} />,
  organization: <FontAwesomeIcon icon={faBuilding} />,
  task: <FontAwesomeIcon icon={faBullseye} />,
  people: <FontAwesomeIcon icon={faPeopleGroup} />,
  users: <FontAwesomeIcon icon={faUsers} />,
  negociation: <FontAwesomeIcon icon={faCommentDollar} />,
  policies: <FontAwesomeIcon icon={faBuildingShield} />,
}
